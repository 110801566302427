import React from "react";
import useArticlesData from "@staticQueries/ArticlesQuery";
import { ArticleCard } from "@molecules";
import { Container } from "@atoms";

const ArticleGrid = ({ articles, filters }) => {
  const defaults = useArticlesData();
  let cards = articles?.length ? articles : defaults;
  if (filters?.length > 0) {
    cards = cards?.filter(card =>
      card.topics?.map(t => t.uid)?.some(t => filters.includes(t))
    );
  }

  return (
    <Container>
      <div className="flex flex-wrap justify-center gap-5">
        {cards?.map(c => (
          <div
            key={c.uid}
            className="flex-grow md:flex-[0_1_calc(50%-.625rem)] lg:flex-[0_1_calc(33.333%-.875rem)]"
          >
            <ArticleCard key={c.uid} {...c} />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default ArticleGrid;
