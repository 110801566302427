import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";

const query = graphql`
  query ArticlesQuery {
    craft {
      entries(section: "articles") {
        ... on Craft_articles_article_Entry {
          uid
          url: uri
          type: typeHandle
          title
          date: postDate
          authors: person0 {
            title
          }
          descriptor: descriptor0
          image: image0 {
            ...ImageFragment
          }
          topics {
            title
            uid
          }
          metaImage {
            ...ImageFragment
          }
          metaDescription
        }
        ... on Craft_articles_whitepaper_Entry {
          uid
          url: uri
          type: typeHandle
          title
          date: postDate
          authors: person0 {
            title
          }
          descriptor: descriptor0
          image: image0 {
            ...ImageFragment
          }
          topics {
            title
            uid
          }
          metaImage {
            ...ImageFragment
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  const articles = entries?.map(ent => {
    return {
      ...ent,
      image: resolveImage(ent.image || ent.metaImage),
    };
  });
  return articles;
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
